.@{wi-css-prefix}-yahoo-0 { content: @tornado; };
.@{wi-css-prefix}-yahoo-1 { content: @day-storm-showers; };
.@{wi-css-prefix}-yahoo-2 { content: @hurricane; };
.@{wi-css-prefix}-yahoo-3 { content: @thunderstorm; };
.@{wi-css-prefix}-yahoo-4 { content: @thunderstorm; };
.@{wi-css-prefix}-yahoo-5 { content: @rain-mix; };
.@{wi-css-prefix}-yahoo-6 { content: @rain-mix; };
.@{wi-css-prefix}-yahoo-7 { content: @rain-mix; };
.@{wi-css-prefix}-yahoo-8 { content: @hail; };
.@{wi-css-prefix}-yahoo-9 { content: @showers; };
.@{wi-css-prefix}-yahoo-10 { content: @hail; };
.@{wi-css-prefix}-yahoo-11 { content: @showers; };
.@{wi-css-prefix}-yahoo-12 { content: @showers; };
.@{wi-css-prefix}-yahoo-13 { content: @snow; };
.@{wi-css-prefix}-yahoo-14 { content: @day-snow; };
.@{wi-css-prefix}-yahoo-15 { content: @snow-wind; };
.@{wi-css-prefix}-yahoo-16 { content: @snow; };
.@{wi-css-prefix}-yahoo-17 { content: @hail; };
.@{wi-css-prefix}-yahoo-18 { content: @rain-mix; };
.@{wi-css-prefix}-yahoo-19 { content: @dust; };
.@{wi-css-prefix}-yahoo-20 { content: @fog; };
.@{wi-css-prefix}-yahoo-21 { content: @windy; };
.@{wi-css-prefix}-yahoo-22 { content: @smoke; };
.@{wi-css-prefix}-yahoo-23 { content: @strong-wind; };
.@{wi-css-prefix}-yahoo-24 { content: @strong-wind; };
.@{wi-css-prefix}-yahoo-25 { content: @snowflake-cold; };
.@{wi-css-prefix}-yahoo-26 { content: @cloudy; };
.@{wi-css-prefix}-yahoo-27 { content: @night-cloudy; };
.@{wi-css-prefix}-yahoo-28 { content: @day-cloudy; };
.@{wi-css-prefix}-yahoo-29 { content: @night-cloudy; };
.@{wi-css-prefix}-yahoo-30 { content: @day-cloudy; };
.@{wi-css-prefix}-yahoo-31 { content: @night-clear; };
.@{wi-css-prefix}-yahoo-32 { content: @day-sunny; };
.@{wi-css-prefix}-yahoo-33 { content: @night-partly-cloudy; };
.@{wi-css-prefix}-yahoo-34 { content: @day-sunny-overcast; };
.@{wi-css-prefix}-yahoo-35 { content: @rain-mix; };
.@{wi-css-prefix}-yahoo-36 { content: @hot; };
.@{wi-css-prefix}-yahoo-37 { content: @day-storm-showers; };
.@{wi-css-prefix}-yahoo-38 { content: @day-storm-showers; };
.@{wi-css-prefix}-yahoo-39 { content: @day-storm-showers; };
.@{wi-css-prefix}-yahoo-40 { content: @showers; };
.@{wi-css-prefix}-yahoo-41 { content: @snow-wind; };
.@{wi-css-prefix}-yahoo-42 { content: @snow; };
.@{wi-css-prefix}-yahoo-43 { content: @snow-wind; };
.@{wi-css-prefix}-yahoo-44 { content: @day-sunny-overcast; };
.@{wi-css-prefix}-yahoo-45 { content: @day-storm-showers; };
.@{wi-css-prefix}-yahoo-46 { content: @snow; };
.@{wi-css-prefix}-yahoo-47 { content: @day-storm-showers; };
.@{wi-css-prefix}-yahoo-3200 { content: @stars; };