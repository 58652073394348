.wi-moon-new:before {content: @moon-new;}
.wi-moon-waxing-cresent-1:before {content: @moon-waxing-cresent-1;}
.wi-moon-waxing-cresent-2:before {content: @moon-waxing-cresent-2;}
.wi-moon-waxing-cresent-3:before {content: @moon-waxing-cresent-3;}
.wi-moon-waxing-cresent-4:before {content: @moon-waxing-cresent-4;}
.wi-moon-waxing-cresent-5:before {content: @moon-waxing-cresent-5;}
.wi-moon-waxing-cresent-6:before {content: @moon-waxing-cresent-6;}
.wi-moon-waxing-gibbous-1:before {content: @moon-waxing-gibbous-1;}
.wi-moon-waxing-gibbous-2:before {content: @moon-waxing-gibbous-2;}
.wi-moon-waxing-gibbous-3:before {content: @moon-waxing-gibbous-3;}
.wi-moon-waxing-gibbous-4:before {content: @moon-waxing-gibbous-4;}
.wi-moon-waxing-gibbous-5:before {content: @moon-waxing-gibbous-5;}
.wi-moon-waxing-gibbous-6:before {content: @moon-waxing-gibbous-6;}
.wi-moon-full:before {content: @moon-full;}
.wi-moon-waning-gibbous-1:before {content: @moon-waning-gibbous-1;}
.wi-moon-waning-gibbous-2:before {content: @moon-waning-gibbous-2;}
.wi-moon-waning-gibbous-3:before {content: @moon-waning-gibbous-3;}
.wi-moon-waning-gibbous-4:before {content: @moon-waning-gibbous-4;}
.wi-moon-waning-gibbous-5:before {content: @moon-waning-gibbous-5;}
.wi-moon-waning-gibbous-6:before {content: @moon-waning-gibbous-6;}
.wi-moon-waning-crescent-1:before {content: @moon-waning-crescent-1;}
.wi-moon-waning-crescent-2:before {content: @moon-waning-crescent-2;}
.wi-moon-waning-crescent-3:before {content: @moon-waning-crescent-3;}
.wi-moon-waning-crescent-4:before {content: @moon-waning-crescent-4;}
.wi-moon-waning-crescent-5:before {content: @moon-waning-crescent-5;}
.wi-moon-waning-crescent-6:before {content: @moon-waning-crescent-6;}